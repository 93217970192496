/**
 * Created by s.samardzic on 1/3/2018.
 */
import {RouterModule, Routes} from "@angular/router";
import { HomeComponent } from './home/home.component';
import { PostComponent } from './post/post.component';
import { ScrapingComponent } from './scraping/scraping.component';
import { ChartComponent } from './chart/chart.component';
import { TrendingComponent } from './trending/trending.component';
import { ScheduledComponent } from './scheduled/scheduled.component';
const routes: Routes  = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'post',
    component: PostComponent
  },
  {
    path: 'scraping',
    component: ScrapingComponent
  },
  {
    path: 'chart',
    component: ChartComponent
  },
  {
    path: 'trending',
    component: TrendingComponent
  },
  {
    path: 'scheduled',
    component: ScheduledComponent
  }
];

export const routing =  RouterModule.forRoot(routes)
