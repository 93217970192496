/**
 * Created by s.samardzic on 1/11/2018.
 */
import {Injectable, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as MyEnvironment from "../../../environments/environment";
import {CacheService} from "../../services/cache";

@Injectable()
export class Category {
  cacheAccounts: any;

  constructor(public _cacheService: CacheService,
              private http: HttpClient,) {
  }

  public save = (category_name, selectedPages) => {

    this.cacheAccounts = this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection);
    let category_params = new HttpParams()
      .set('name', category_name)
      .set('pages', JSON.stringify(selectedPages))
      .set('user_id', this.cacheAccounts.authResponse.userID);
    return this.http.post(MyEnvironment.environment.backendBaseUrl + 'categories/insert/', category_params);

  };
  public update = (category_name, selectedPages) => {
    this.cacheAccounts = this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection);
    const category_params = new HttpParams()
      .set('name', category_name)
      .set('pages', JSON.stringify(selectedPages))
      .set('user_id', this.cacheAccounts.authResponse.userID);
    return this.http.post(MyEnvironment.environment.backendBaseUrl + 'categories/update/', category_params);

  };
  public get = () => {
    this.cacheAccounts = this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection);
    let category_params = {
      params: {
        'userId': this.cacheAccounts.authResponse.userID
      }
    };
    return this.http.get(MyEnvironment.environment.backendBaseUrl + 'categories/search', category_params);

  };
  public delete = (category) => {
    this.cacheAccounts = this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection);
    let category_params = {
      params: {
        'userId': this.cacheAccounts.authResponse.userID,
        'id': category.id
      }
    };
    return this.http.get(MyEnvironment.environment.backendBaseUrl + 'categories/delete/', category_params);

  };

}
