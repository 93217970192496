import {Component, OnInit} from '@angular/core';
import {FacebookService, InitParams, LoginOptions} from 'ngx-facebook';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import * as MyEnvironment from './../../environments/environment';
import * as _ from 'lodash';
import {CacheService} from '../services/cache';
import {Category} from '../services/facebook/category';
import {ToastrService} from 'ngx-toastr';
import {EventService} from "../services/eventService";
import {Auth} from '../services/facebook/auth';
import {SocketService} from '../services/socket';
import {JoyrideService} from "ngx-joyride";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [Category]
})
export class HomeComponent implements OnInit {
  isDisabled = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  userName: String;
  isLinear = false;
  private backend;

  constructor(private fb: FacebookService,
              private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              public _cacheService: CacheService,
              private toastr: ToastrService,
              private auth: Auth,
              private eventService: EventService,
              private category: Category,
              private socketService: SocketService,
              private readonly joyrideService: JoyrideService) {
   // this.login();

  }

  goToPost = () => {
    this._cacheService.set('userName', this.userName);
    if(this.route.snapshot.queryParams.passUrl) {
      this.router.navigate(['/post'], {queryParams: {passUrl: this.route.snapshot.queryParams.passUrl}});
    } else {
      this.router.navigate(['/post']);
    }
  }
  connectUser = (user_id) => {
    let backend = this.socketService.initSocket(MyEnvironment.environment.socketEndPoint, user_id);
    // then
    backend.on('connect', () => {
     console.log('socket', backend);
    });
    backend.on('stats', (data) => {
      MyEnvironment.environment.currentUser = data.data;
      this.toastr.success('Successful logged in num clients: ' + data.numClients);
      this.toastr.success('Welcome!  Fetching your information....');

    });
  }
  login = () => {
    if (localStorage.getItem('closeTour') !== 'true') {
      setTimeout(() => {
        this.joyrideService.startTour(
          {steps: ['username_input', 'username']} // Your steps order
        );
      }, 1000);
    }
    let initParams: InitParams = {
      appId: MyEnvironment.environment.appID,
      xfbml: true,
      version: 'v7.0'
    };
    this.fb.init(initParams);
    const options: LoginOptions = {
      scope: 'public_profile,email,pages_show_list, pages_manage_posts',
      return_scopes: true,
      enable_profile_selector: true
    };
    console.log('this._cacheService.get(Environment.EVENTS.accountsSelection)', this.
    _cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection));
    if (!this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection)) {
      this.fb.login(options)
        .then((response: any) => {
          if (response.status === 'connected') {
            this.isDisabled = false;
            console.log('Facebook user info: ', response);
            this._cacheService.set(MyEnvironment.environment.EVENTS.accountsSelection, response);
            this.eventService.publish(MyEnvironment.environment.EVENTS.authorized, true);
            let user_id = response.authResponse.userID;
            let app_secret = MyEnvironment.environment.secret;
            let access_token = response.authResponse.accessToken;
            let app_id = MyEnvironment.environment.appID;

            this.auth.login(user_id).subscribe((response: any) => {
              if (!response.authenticated) {
                this.auth.newUser(user_id, app_secret, app_id, access_token).subscribe(() => {
                  this.category.save('Default', []);
                  this.connectUser(user_id);
                });
              } else {
                this.connectUser(user_id);
              }

            });
          } else {
            this.toastr.error('Please log into Facebook. Status: ' + response.status);
          }
        });
    } else {
      this.toastr.info('You are already logged in!');
    }

  }
  refreshTokens = () => {

    const cacheAccounts = this._cacheService.get(MyEnvironment.environment.EVENTS.accountsSelection);
    this.backend.on('/posts/refresh/tokens/done', function () {
      this.toastr.success('Tokens are refreshed');
      this.backend.removeListener('/posts/refresh/tokens/done');
    });
    this.backend.emit('/posts/refresh/tokens', {
      pages: cacheAccounts.accountsSelection.pages,
      userId: cacheAccounts.authResponse.userID
    });

  }
  save = () => {
    var userQuery = '/?user=' + this.userName;
    window.location.href = userQuery;
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      userName: ['', Validators.required]
    });
    if (localStorage.getItem('closeTour') !== 'true') {
      this.joyrideService.startTour(
        {steps: ['login']} // Your steps order
      );
    }
  }
  stepSelectionChange($event) {
    if (localStorage.getItem('closeTour') !== 'true') {
      if ($event.selectedIndex === 2) {
        setTimeout(() => {
          this.joyrideService.startTour(
            {steps: ['goToPost', 'refreshTokens']} // Your steps order
          );
        }, 1000);
      }
    }
  }

}
