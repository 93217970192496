/**
 * Created by s.samardzic on 1/11/2018.
 */
/**
 * Created by s.samardzic on 1/9/2018.
 */
import { Injectable } from '@angular/core';
import {FacebookService} from 'ngx-facebook';

@Injectable()
export class LinkPreview {
  constructor(private fb: FacebookService) {
  }
  public get(url) {
    return this.fb.api('/', 'post', {
      id: url,
      scrape: true
    });
  }

}
