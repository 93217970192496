/**
 * Created by s.samardzic on 1/8/2018.
 */
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as MyEnvironment from "../../../environments/environment";

@Injectable()
export class Auth {
  user = {id: 1, name: 'Hello'};

  constructor(private http: HttpClient) {
  }

  login = (user_id) => {
    const params = new HttpParams().set('user_id', user_id);
    return this.http.post(MyEnvironment.environment.backendBaseUrl + 'facebook/auth', params);
  }
  newUser = (user_id, app_secret, app_id, access_token) => {
    const params =
      new HttpParams()
        .set('user_id', user_id)
        .set('appSecret', app_secret)
        .set('appId', app_id)
        .set('access_token', access_token);
    return this.http.post(MyEnvironment.environment.backendBaseUrl + 'facebook/new', params);
  }
}
