/**
 * Created by s.samardzic on 1/15/2018.
 */
export class EventService {

  event = {};

  subscribe(eventName, triggerFn) {
    this.event[eventName] = this.event[eventName] || [];
    this
      .event[eventName]
      .push(triggerFn);
  }

  unSubscribe(eventName, triggerFn) {
    this.event[eventName] = [];
  }

  publish(eventName, data) {
    let event = this.event[eventName];
    if (event && event.length) {
      this
        .event[eventName]
        .forEach(function (triggerFn) {
          triggerFn(data);
        });
    } else {
      console.log('Nobody subcribed to ' + eventName + ' event');
    }

  }
}
