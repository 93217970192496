import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scraping',
  templateUrl: './scraping.component.html',
  styleUrls: ['./scraping.component.css']
})
export class ScrapingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
