/**
 * Created by s.samardzic on 1/15/2018.
 */
import {
  OnInit
} from '@angular/core';
import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CacheService} from '../services/cache';
import * as MyEnvironment from '../../environments/environment';
import * as moment from 'moment';
import {SocketService} from '../services/socket';
@Injectable()
export class PostService implements OnInit {
  all: any = [];
  user_id: Number;

  constructor(public toastr: ToastrService,
              private _cacheService: CacheService,
              private socketService: SocketService) {
  }

  trackId = (link) => {
    let expr = "trackBy=";
    return link.indexOf(expr) !== -1;
  }
  public send = (post) => {
    let accumulatedPosts: any = [];

    if (!post.time) {
      this.toastr.error('You need to define time');
      return;
    }

    let selectedPages = this._cacheService.get(MyEnvironment.environment.EVENTS.pagesSelection);

    if (selectedPages && selectedPages.length) {
      selectedPages.map((page) => {
        return {
          "id": page.id,
          "ac": page.access_token,
          "type": 'page',
          "namePage": page.name
        };
      });
    } else {
      this.toastr.error('You need to select some pages');
      return;
    }

    for (let i = 0; i < selectedPages.length; i++) {
      let postObj: any = {};
      let pageToken = selectedPages[i];
      let newMoment: any = moment(post.time);
      //this is for calendar this is minimum range to be displayed item
      let plusOneHour = newMoment.add(30, 'minute');
      let calendarObj = {
        "title": post.message,
        "startsAt": newMoment.utc().format(),
        "endsAt": plusOneHour.utc().format(),
        "start": newMoment.utc().format(),
        "end": plusOneHour.utc().format(),
        "color": '#e3bc08',
        "draggable": 1,
        "resizable": 1
      };
      let query = this.trackId(post.link) ? '&page=' + pageToken.namePage : '';
      postObj = {
        message: pageToken.name,
        access_token: pageToken.access_token,
        scheduled_publish_time: moment(post.time).unix(),
        link: post.link + query,
        picture: post.picture,
        published: true,
        to: pageToken.id,
        from: pageToken.id,
        type: post.type,
        typetype: post.type
      }
      if (post.caption) {
        postObj.caption = post.caption;
      }

      if (post.type === 'photos') {
        postObj = {
          published: false,
          url: post.picture,
          link: post.picture
        }
      }
      let category = this._cacheService.get(MyEnvironment.environment.EVENTS.categorySelection);
      postObj.category = JSON.stringify(category);
      this.user_id = this._cacheService.get('accountsSelection').authResponse.userID;
      postObj.user_id = this.user_id;
      postObj.appId = MyEnvironment.environment.appID;
      postObj.appSecret = MyEnvironment.environment.secret;
      postObj["delayPosts"] = post.delayPosts;
      accumulatedPosts.push(Object.assign(postObj, calendarObj));
    }
    let backend = this.socketService.connection;
    backend.on('/posts/insert/done', () => {
      this.toastr.success('You success  scheduled ' + accumulatedPosts.length + ' posts');
      accumulatedPosts = [];
      backend.off('/posts/insert/done');
    });
    backend.emit('/posts/insert/', accumulatedPosts);
  }

  ngOnInit() {

  }
}
