import {
  OnInit,
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import {Subject} from 'rxjs/Subject';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewDay,
  CalendarEventTimesChangedEvent
} from 'angular-calendar';
import {SocketService} from '../services/socket';
import {CacheService} from '../services/cache';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material';
import {SelectionModel} from '@angular/cdk/collections';
import * as MyEnvironment from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./scheduled.component.css']
})
export class ScheduledComponent implements OnInit {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  view: string = 'month';
  user_id: any;
  startAt:any;
  option: string = 'link';
  type: string = 'feed';
  page: string = '';
  link: string = '';
  endAt:any;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  loading$:any;
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({event}: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
  links: any = [];
  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal,
              public _cacheService: CacheService,
              public router: Router,
              private socketService: SocketService,
              private http: HttpClient,
              private toastr: ToastrService) {
  }
  displayedColumns = ['select', 'id', 'message'];
  dataSource = new MatTableDataSource<CalendarEvent>(this.links);
  selection = new SelectionModel<CalendarEvent>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
  eventTimesChanged({
                      event,
                      newStart,
                      newEnd
                    }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('Dropped or resized', event);
    this.refresh.next();
  }

  dayClicked({date, events}: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }

  }
  handleEvent(action: string, event: any): void {
    this.modalData = {event, action};
    this.links = event.links;
    this.dataSource = new MatTableDataSource<any>(this.links);
    console.log('this.links', this.links);
    this.modal.open(this.modalContent, {size: 'lg'});
  }
  fetchEvents = () => {
    if (this.view) {
      if (this.view === 'month') {
        this.startAt = moment(this.viewDate).startOf('month').unix();
        this.endAt = moment(this.viewDate).endOf('month').unix();
      } else if (this.view === 'year') {
        this.startAt = moment(this.viewDate).startOf('year').unix();
        this.endAt = moment(this.viewDate).endOf('year').unix();
      } else {
        this.startAt = moment(this.viewDate).startOf('day').unix();
        this.endAt = moment(this.viewDate).endOf('day').unix();
      }
    }

    this.user_id = this._cacheService.get('accountsSelection').authResponse.userID;
    let backend = this.socketService.connection;
    this.loading$ = new Promise(resolve => {
      backend.on('/posts/search/done', (response) => {
        var posts = response.Posts;
        posts.forEach((post) => {
          post.start = new Date(post.start);
          post.end = new Date(post.end);
          post.color = {
            primary: post.color,
            secondary: post.color
          };
          post.actions = this.actions;
        });
        this.events = posts;
        console.log('posts', posts);
        backend.off('/posts/search/done');
        resolve(true);
      });
    });

    backend.emit('/posts/search/', {
      userId: this.user_id,
      page: this.page,
      link: this.link,
      type: this.type,
      startAt: this.startAt,
      endAt: this.endAt,
      option: this.option
    });

  }
  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(cell => {
      const groups: any = {};
      cell.events.forEach((event: any) => {
        groups[event.type] = groups[event.type] || [];
        groups[event.type].push(event);
      });
      cell['eventGroups'] = Object.entries(groups);
    });
  }
  ngOnInit() {
    if (!this._cacheService.get('accountsSelection')){
      return this.router.navigate(['/']);
    }
    this.fetchEvents();
  }
  // deleteClient (ids) {
  //   for (let i = 0; i < this.events.length; i++) {
  //     const links: any = this.events[i].links;
  //     if (links) {
  //       for (let j = 0; j < links.length; j++) {
  //         const readyForDeleteLinks = ids.filter(function (id) {
  //           return links[j].id === id;
  //         }).length > 0;
  //         if (readyForDeleteLinks) {
  //           links.splice(j, 1);
  //         }
  //       }
  //     } else {
  //       const readyForDeleteLink = ids.filter(function (id) {
  //         return this.events[i].id === id;
  //       }).length > 0;
  //       if (readyForDeleteLink) {
  //         this.events.splice(i, 1);
  //       }
  //     }
  //   }
  // }
  delete() {
    const ids = _.map(this.selection.selected, 'id');
    this.http.post(MyEnvironment.environment.backendBaseUrl
    + 'posts/delete/?userId=' + this._cacheService.get('accountsSelection').authResponse.userID, ids)
        .subscribe(function () {
          this.toastr.info(ids.length + ' posts deleted');
          if (ids.length) {

          }

        });

  }

}
export interface Element {
  message: string;
  id: number;
}

