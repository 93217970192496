import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import * as _ from 'lodash';
import {CacheService} from '../services/cache';
import {EventService} from "./../services/eventService";
import * as MyEnvironment from "../../environments/environment";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navLinks: any[];
  activeLinkIndex = 0;

  constructor(
    private router: Router,
    public _cacheService: CacheService,
    private eventService: EventService) {
    // @ts-ignore
    router.events
      .filter((event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        console.log('NavigationEnd:', event.url);
        this.activeLinkIndex = _.findIndex(this.navLinks, function (tab) {
          return tab.path === event.url;
        });
      });
  }
  public createNav = (allow) => {
    if(!allow){
      this.navLinks = [
        {
          path: '/',
          label: 'Home'
        }
      ];
    } else {
      this.navLinks = [
        {
          path: '/',
          label: 'Home'
        },
        {
          path: '/post',
          label: 'Post'
        },
        {
          path: '/scheduled',
          label: 'Scheduled'
        },
        {
          path: '/scraping',
          label: 'Scraping'
        },
        {
          path: '/trending',
          label: 'Trending'
        }
      ];
    }
  }


  ngOnInit() {
    this.createNav(false);
    this.eventService.subscribe(MyEnvironment.environment.EVENTS.authorized, (allow)=>{
      if(allow) {
        this.createNav(allow);
      }
    })
  }

}
