import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {FacebookService} from 'ngx-facebook';
import {HttpClient} from '@angular/common/http';
import {Category} from "../services/facebook/category";
import {LinkPreview} from "../services/facebook/linkPreview";
import {CacheService} from "../services/cache";
import {PostService} from "../services/postService";
import * as MyEnvironment from "../../environments/environment";
import {ActivatedRoute, Router} from '@angular/router';

import * as _ from 'lodash';
declare var $: any;
import * as moment from 'moment';
import {JoyrideService} from "ngx-joyride";

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
  providers: [Category]
})
export class PostComponent implements OnInit, AfterViewChecked {
  pages: any;
  public selectedPages: any;
  public categories: any;
  cacheAccounts: any;
  link: any;
  url: string;
  image: any;
  profileImage: string;
  scheduleTime: any;
  userName: String;
  delayBetweenPosts: Number = 5;
  boostLink: any;
  postData: any;
  constructor(private fb: FacebookService,
              private http: HttpClient,
              private linkPreview: LinkPreview,
              private category: Category,
              private _cacheService: CacheService,
              private route: ActivatedRoute,
              public router: Router,
              private postService: PostService,
              private readonly joyrideService: JoyrideService) {
    if(this.route.snapshot.queryParams.passUrl) {
      this.onUrlChanges(decodeURIComponent(this.route.snapshot.queryParams.passUrl));
    }
  }

  getNextPages = (data, resolve, reject) => {

    if (data.paging && data.paging.next) {
      this.http.get(data.paging.next).subscribe((response: any) => {
        if (response.paging.next || response.data.length) {
          // push data u array
          this.pages = this.pages.concat(response.data);
          this.getNextPages(response, resolve, reject)
        } else {
          return resolve(this.pages);
        }
      }, function (reject) {
        reject(reject)
      });
    } else {
      return resolve(this.pages);
    }

  }
  adjustToChips = (pages) => {
    let modifiedPages = pages.map((page) => {
      return Object.assign(page, {
        value: page.id,
        display: page.name
      });
    });
    return modifiedPages;
  }
  isUrl = (link) => {
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(link);
  }
  isImage = (link) => {
    const regexp = (/\.(gif|jpg|jpeg|tiff|png)$/i)
    return regexp.test(link);
  }
  isBitLy = (link) => {
    return link.indexOf('bit.ly') > -1;
  }
  onScheduleLink = () => {
    this.scheduleTime = moment(this.scheduleTime).valueOf();

    let postBase = {
      link: "",
      name: "",
      message: "",
      description: "",
      picture: "",
      type: "feed",
      time: this.scheduleTime,
      delayPosts: this.delayBetweenPosts,
      caption: ""
    };

    if (this.isBitLy(this.url)) {
      this.postData = {
        boostLink: true,
        link: this.boostLink,
      };
    } else if (this.isImage(this.url)) {

    } else {
      // regular link
      this.postData = {
        boostLink: false,
        link: this.url,
        message: this.link.title,
        title: this.link.title,
        picture: this.link.image
      };
    }
    this.postService.send(Object.assign(postBase, this.postData));

  }

  onCategoryChanged = () => {
    console.log('selectedPages', this.selectedPages);
    this.category.update('Default', this.selectedPages)
      .subscribe((response) => {
        console.log('categories/update/', response);
        this.createCategories();
    });
  }
  onCategoryEdited = (category) => {
    console.log('onCategoryEdited', category);
    // ToDo update backend to update category by id not by name
    this.category.update(category.name, this.selectedPages) .subscribe((response) => {
      console.log('categories/update/', response);
      this.createCategories();
    });
  }
  onCategorySelected = (category) => {
    console.log('onCategorySelected', category);
    this.selectedPages = JSON.parse(category.pages);
    this._cacheService.set(MyEnvironment.environment.EVENTS.pagesSelection, this.selectedPages);
    this.category.update('Default', this.selectedPages) .subscribe((response) => {
      console.log('categories/update/', response);
      this.createCategories();
    });
  }
  onCategoryAdded = (category) => {
    console.log('onCategoryAdded', category);
    this.category.save(category.name, this.selectedPages).subscribe((response) => {
      console.log('categories/insert/', response);
      this.createCategories();
    });
  }
  createCategories = () => {
    this.category.get()
      .subscribe((response: any) => {
        console.log('categories/search', response);
        // make default category undeletable
        response.Categories.forEach((category) => {
          if (category.name == 'Default') {
            category['readonly'] = true;
          }
        });
        this.categories = response.Categories;
        console.log('this.categories', this.categories);
        const defaultCategory: any = _.find(this.categories, {name: 'Default'});
        if (defaultCategory) {
          this.selectedPages = JSON.parse(defaultCategory.pages);
          this._cacheService.set(MyEnvironment.environment.EVENTS.pagesSelection, this.selectedPages);
        } else {
          this.category.save('Default', []).subscribe((response) => {
            console.log('categories/insert/', response);
            this.createCategories();
          });
        }
      });
  }
  onCategoryRemove = (category) => {
    this.category.delete(category).subscribe(() => {
      console.log('onCategoryRemove', category);
      this.createCategories();
    });
  }
  onUrlChanges = (link) => {
    this.url = link;
    if (this.isUrl(link)) {
      this.joyrideService.closeTour();
      localStorage.setItem('closeTour', 'true');
      this.linkPreview.get(this.url).then((response: any) => {
        if (response && !response.error) {
          this.image = _.first(response.image) || {
            url: 'https://png.pngtree.com/png-vector/20190223/ourmid/pngtree-vector-picture-icon-png-image_695350.jpg'
          };
          this.link = Object.assign(response, {
            image: this.image.url
          });

        }
      });
    } else {
      this.image = null;
    }
  }

  ngAfterViewChecked() {
    $('ngx-content-loading g g:last-child rect').css({
      width: '100%'
    });
  }

  getProfileImage = () => {
    return `url('${this.profileImage}')`;
  }

  ngOnInit() {
    if (localStorage.getItem('closeTour') !== 'true') {
      setTimeout(() => {
        this.joyrideService.startTour(
          {steps: ['selectPages', 'createCategory', 'url']} // Your steps order
        );
      }, 1000);
    }
    if (!this._cacheService.get('accountsSelection')){
      return this.router.navigate(['/']);
    }
    this.userName = this._cacheService.get('userName');
    const deferedPages = new Promise((resolve, reject) => {
      this.fb.api('/me/accounts', 'get', {}).then((response) => {
        if (!response || response.error) {
          reject({
            error: 'Error occured'
          });
        } else {
          this.pages = response.data;
          return this.getNextPages(response, resolve, reject)
        }
      })
    });
    deferedPages.then((pages) => {
      console.log('allPages', pages);
      this.pages = this.adjustToChips(pages);
    });
    this.createCategories();
    this.link = {
      title: '',
      subtitle: '',
      image: ''
    }
    this.fb.api('/me/picture', 'get', {
      height: 150
    }).then((response) => {
      this.profileImage = response.data.url;
    });
    this.boostLink = decodeURIComponent(this.route.snapshot.queryParamMap.get('link'));

  }

}
