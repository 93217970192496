import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {routing}  from './app.routes';
import {HomeComponent} from './home/home.component';
import {PostComponent} from './post/post.component';
import {ScrapingComponent} from './scraping/scraping.component';
import {ChartComponent} from './chart/chart.component';
import {TrendingComponent} from './trending/trending.component';
import {ScheduledComponent} from './scheduled/scheduled.component';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';

import * as MyEnvironment from '../environments/environment';
import {Auth} from './services/facebook/auth';
import {SocketService} from './services/socket';
import {CacheService} from './services/cache';
import {PostService} from './services/postService';
import {Category} from './services/facebook/category';
import {LinkPreview} from "./services/facebook/linkPreview";
import {EventService} from "./services/eventService";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import {
  MatChipsModule,
  MatSelectModule,
  MatTabsModule,
  MatExpansionModule,
  MatButtonModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FacebookModule} from 'ngx-facebook';
import {FlexLayoutModule} from "@angular/flex-layout";
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
import {ToastrModule} from 'ngx-toastr';
import {TagInputModule} from 'ngx-chips';
import { NgxContentLoadingModule } from 'ngx-content-loading';
import { CalendarModule } from 'angular-calendar';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {JoyrideModule} from "ngx-joyride";
import {MatTableModule} from "@angular/material/table";


const config: SocketIoConfig = { url: MyEnvironment.environment.socketEndPoint, options: {}};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PostComponent,
    ScrapingComponent,
    ChartComponent,
    TrendingComponent,
    ScheduledComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    routing,
    BrowserAnimationsModule,
    MatTabsModule,
    TagInputModule,
    MatChipsModule,
    MatSelectModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatExpansionModule,
    FacebookModule.forRoot(),
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    SocketIoModule.forRoot(config),
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxContentLoadingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CalendarModule.forRoot(),
    NgbModule.forRoot(),
    JoyrideModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    Auth,
    SocketService,
    CacheService,
    Category,
    LinkPreview,
    EventService,
    PostService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
