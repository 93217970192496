// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  password: "Zovjunice89",
  crossAllowed: "*",
  backendBaseUrl: "https://ollo.click:3333/",
  socketEndPoint: "https://ollo.click:3002",
  currentUser: {
    userId: '',
    userName: ''
  },
  appID: "762563111151230",
  secret: "a3e0e410ceb21215ac7ab8c10f146e1e",
  EVENTS: {
    accountsSelection: 'accountsSelection',
    pagesSelection: 'pagesSelection',
    groupsSelection: 'groupsSelection',
    postAdded: 'postAdded',
    deletePosts: 'deletePosts',
    categorySelection: 'categorySelection',
    categorySelect: 'categorySelect',
    filtersChanged: 'filtersChanged',
    modalClosed: 'modalClosed',
    ngRepeatFinished: 'ngRepeatFinished',
    authorized: 'authorized'
  }
};
