/**
 * Created by s.samardzic on 1/9/2018.
 */
import { Injectable } from '@angular/core';

import * as socketIo from 'socket.io-client';

@Injectable()
export class SocketService {
  public connection;

  public initSocket(url, user) {
    return this.connection = socketIo(url + '/' + user ).connect();
  }

}
